export const restrictedCountries = [
  'CI',
  'JO',
  'MM',
  'IQ',
  'BI',
  'BY',
  'BO',
  'CN',
  'SY',
  'CU',
  'LY',
  'MA',
  'SO',
  'ZW',
  'IR',
  'LR',
  'SD',
  'YE',
  'CD',
  'CG',
  'DZ',
  'KP',
  'US',
  'VE',
  'BD',
  'ML',
  'NP',
  'RU'
] as string[]
